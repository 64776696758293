import React, { Component } from 'react';
import { dateUtils } from '../shared/utilities/dateUtils';
import * as Enums from '../shared/appConfig/enums';
import { arraysEqual } from '../shared/utilities/arrayUtils';

const pickupCancelledStatus = Enums.pickupCancelledStatus;

class TripProgress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tripProgress: [
                {
                    Status: Enums.PickupStatus.ProcessingConfirmation,
                    StatusTimestamp: ''
                },
                {
                    Status: Enums.PickupStatus.TripStarted,
                    StatusTimestamp: ''
                },
                {
                    Status: Enums.PickupStatus.DriverArrived,
                    StatusTimestamp: ''
                },
                {
                    Status: Enums.PickupStatus.LoadingItems,
                    StatusTimestamp: ''
                },
                {
                    Status: Enums.PickupStatus.DrivingToDropoff,
                    StatusTimestamp: ''
                },
                {
                    Status: Enums.PickupStatus.UnloadingItems,
                    StatusTimestamp: ''
                },
                {
                    Status: Enums.PickupStatus.PickupCompleted,
                    StatusTimestamp: ''
                }
            ],
            latestTripProgress: this.props.latestTripProgress,
            timezoneIdentifier: this.props.timezoneIdentifier
        }
    }

    /**
     * componentDidMount()
     * This function Executes only after the first render.
     */
    componentDidMount = () => {
        this.updateStatusFeed();
    }

    /**
     * updateStatusFeed()
     * This function is used to get the status feed
     */
    updateStatusFeed = async() => {
        try{
            let updateTripProgress = [...this.state.tripProgress];
            const returnStatuses = [
                {
                    Status: Enums.PickupStatus.DrivingToReturn,
                    StatusTimestamp: ''
                },
                {
                    Status: Enums.PickupStatus.UnloadingAtReturn,
                    StatusTimestamp: ''
                },
            ]

             // If statuses contains 28 status which is delivery completed by one driver, replace 62:PickupCompleted with 28: TripCompleted status. 
            if (this.props.latestTripProgress?.some((state) => state.Status === Enums.PickupStatus.TripCompleted) && this.props.noOfDrivers === 2) {
                let pickupCompleted = updateTripProgress?.find(state => state.Status === Enums.PickupStatus.PickupCompleted)
                if (pickupCompleted) pickupCompleted.Status = Enums.PickupStatus.TripCompleted
            }

            // If statuses contains return status, add return statuses to timeline 
            if(this.props.latestTripProgress?.some((state)=> state.Status === Enums.PickupStatus.DrivingToReturn )){
                if(!updateTripProgress?.some(state=>state.Status === Enums.PickupStatus.DrivingToReturn))
                    updateTripProgress.splice( 6, 0, ...returnStatuses )
            }
            let progressStatus = [];
            for (let i = 0; i < this.props.latestTripProgress.length; i++) {
                let timeStamp = dateUtils.convertTimezone(this.props.latestTripProgress[i].StatusTimestamp, this.state.timezoneIdentifier, `MMM DD [at] h:mm A [${this.props.timezoneDisplayName}]`)
                progressStatus.push({ Status: this.props.latestTripProgress[i].Status, StatusTimestamp: timeStamp })
                // If latestTripProgress contains status 4 only 
                if(
                    (this.props.latestTripProgress.length === 1) 
                    && (this.props.latestTripProgress[0].Status === Enums.PickupStatus.ProcessingConfirmation ||
                        this.props.latestTripProgress[0].Status === Enums.PickupStatus.AwaitingCustomerConfirmation
                        )
                ){
                    const tmp = [
                        {
                            Status: Enums.PickupStatus.ProcessingConfirmation,
                            StatusTimestamp: ''
                        },
                        {
                            Status: Enums.PickupStatus.TripStarted,
                            StatusTimestamp: ''
                        },
                        {
                            Status: Enums.PickupStatus.DriverArrived,
                            StatusTimestamp: ''
                        },
                        {
                            Status: Enums.PickupStatus.LoadingItems,
                            StatusTimestamp: ''
                        },
                        {
                            Status: Enums.PickupStatus.DrivingToDropoff,
                            StatusTimestamp: ''
                        },
                        {
                            Status: Enums.PickupStatus.UnloadingItems,
                            StatusTimestamp: ''
                        },
                        {
                            Status: Enums.PickupStatus.PickupCompleted,
                            StatusTimestamp: ''
                        }
                    ];

                    if(this.props.latestTripProgress[0].Status === Enums.PickupStatus.AwaitingCustomerConfirmation){
                        tmp[0].Status = Enums.PickupStatus.AwaitingCustomerConfirmation
                    }
                    tmp[0].StatusTimestamp = timeStamp;
                    for(const item of tmp){
                        if(item.Status !== Enums.PickupStatus.ProcessingConfirmation){
                            const value = document.getElementById(`${item.Status}`);
                            if(value) value.classList.remove('completed');
                        }
                    }
                    await this.setState({
                        tripProgress: tmp,
                        ShowTripCanceled: false,
                    })
                    break;
                }
                if (this.props.latestTripProgress[i].Status === Enums.PickupStatus.PickupCompleted) {
                    await document.getElementById('62').classList.add('completed');
                    await this.setState({
                        tripProgress: progressStatus
                    })
                }
                else if (pickupCancelledStatus.includes(this.props.latestTripProgress[i].Status)) {
                    var arrDeletedItems = pickupCancelledStatus.slice();
                    arrDeletedItems.splice(4, 1); /* new pickupCancelledStatus array with status 8, 13, 66,69*/
                    if (arrDeletedItems.includes(this.props.latestTripProgress[i].Status)) {
                        await this.setState({
                            ShowTripCanceled: true, /* ShowTripCanceled text*/
                        })
                    }
                    await this.setState({
                        tripProgress: progressStatus
                    })
                }
                else { 
                    if (updateTripProgress[i].Status === progressStatus[i].Status) {
                        updateTripProgress[i].StatusTimestamp = progressStatus[i].StatusTimestamp;
                        await document.getElementById(updateTripProgress[i].Status).classList.add('completed');
                        await this.setState({
                            tripProgress: updateTripProgress
                        })
                    } else if (progressStatus[i].Status === Enums.PickupStatus.PickupAccepted) {
                        updateTripProgress[i].Status = Enums.PickupStatus.PickupAccepted;
                        updateTripProgress[i].StatusTimestamp = progressStatus[i].StatusTimestamp;
                        await this.setState({
                            tripProgress: updateTripProgress
                        })
                    }
                }
            }
        }
        catch(err){
            console.log(err);
        }
    }

    /**
     * componentDidUpdate()
     * This function is called as soon as the props or states are updated.
     */
    componentDidUpdate = async (prevProps) => {
        try{
            // Update status feed only if there is new change in status list
            if(!arraysEqual(prevProps.latestTripProgress, this.props.latestTripProgress)) this.updateStatusFeed();
        }
      catch(err){
          console.log(err);
      } 
    }

    render() {
        return (
            <React.Fragment>
                <div className="status-feed">
                    <div className="content">
                        <label>STATUS FEED</label>
                    </div>

                    <div className="delivery-status">
                        {
                            this.state.tripProgress.map((item) => {
                                let statusLabel;
                                switch (item.Status) {
                                    case Enums.PickupStatus.AwaitingCustomerConfirmation: statusLabel = "Awaiting Customer Confirmation"; break;
                                    case Enums.PickupStatus.ProcessingConfirmation: statusLabel = "Assigning Driver(s)"; break;
                                    case Enums.PickupStatus.PickupAccepted: statusLabel = "Scheduled"; break;
                                    case Enums.PickupStatus.TripStarted: statusLabel = "En Route To Pickup"; break;
                                    case Enums.PickupStatus.DriverArrived: statusLabel = "Driver Arrived At Pickup"; break;
                                    case Enums.PickupStatus.LoadingItems: statusLabel = "Loading Items"; break;
                                    case Enums.PickupStatus.DrivingToDropoff: statusLabel = "Driving To Drop Off"; break;
                                    case Enums.PickupStatus.UnloadingItems: statusLabel = "Unloading Items"; break;
                                    case Enums.PickupStatus.DrivingToReturn: statusLabel = "Driving To Return"; break;
                                    case Enums.PickupStatus.UnloadingAtReturn: statusLabel = "Unloading Items At Return"; break;
                                    case Enums.PickupStatus.TripCompleted: statusLabel = "Driver 1 Of 2 Complete"; break;
                                    case Enums.PickupStatus.PickupCompleted: statusLabel = "Completed"; break;
                                    case Enums.PickupStatus.DriverCancelled: statusLabel = "Canceled"; break;
                                    case Enums.PickupStatus.AdminCancelled: statusLabel = "Canceled"; break;
                                    case Enums.PickupStatus.AdminCancelledWithFee: statusLabel = "Canceled"; break;
                                    case Enums.PickupStatus.PartnerCancelled: statusLabel = "Canceled"; break;
                                    case Enums.PickupStatus.PickupReqErrored: statusLabel = "Canceled"; break;
                                    case Enums.PickupStatus.ScheduledPickupComplete: statusLabel = "Canceled"; break;
                                    default: statusLabel = "Assigning Driver(s)"; break;
                                }
                                return <div className={"trip-progress-icon " + statusLabel.split(' ')[0]} key={item.Status} id={item.Status}>
                                    <div className="content">
                                        <label className="status" >{statusLabel}</label>
                                        <p className="timeStamp">{item.StatusTimestamp}</p>
                                    </div>
                                </div>
                            })

                        }
                    </div>
                    {
                        this.state.ShowTripCanceled && <p className="trip-Canceled-text">This delivery has been canceled. You can text our Bungii support team for more details.</p>
                    }
                </div>

            </React.Fragment>
        );
    }
}

export default TripProgress;
